import React, { useEffect, useState } from 'react';
import '../ProfileCom/Profile.css';
import { MdOutlineModeEdit } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import ProfileModal from '../ModalCom/profileModal';
import AddressModal from '../ModalCom/AddressModal';
import { get, post } from '../../services/ApiRouting';
import { RxCross2 } from "react-icons/rx";
import { message } from 'antd';
import { useSelector } from 'react-redux';
import Footer from "../FooterComponent/Footer"
const Profile = () => {
    const profileData = JSON.parse(localStorage.getItem('profile'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [allAddressData, setAlladdressData] = useState([]);
    console.log("allAddressData", allAddressData)
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const addressShowModal = () => {
        setIsAddressModalOpen(true);
    };

    const addressHandleCancel = () => {
        setIsAddressModalOpen(false);
    };

    const fetchAddresses = async () => {
        try {
            const response = await get(`/address.php?action=fetch&limit=10&page=1&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&user_id=${profileData.user_id}`);
            const addressListData = response.list;
            setAlladdressData(addressListData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchAddresses();
    }, [isAddressModalOpen]);

    const updateFun = async (elem) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('admin_token', "OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH");
            formDataToSend.append('action', "update");
            formDataToSend.append('is_active', "0");
            formDataToSend.append('user_id', profileData.user_id);
            formDataToSend.append('address_id', elem.address_id);
            const response = await post('/address.php', formDataToSend);
            fetchAddresses(); // Refresh the address list after update
        } catch (error) {
            console.error('Error submitting form:', error);
            message.error('Failed to update address');
        }
    };

    console.log("profileData",profileData)

    return (
        <>
            <section className='profile-section-details-con'>
                <div className='container'>
                    <div className='order-section-order-list-head'>
                        <h2>Profile</h2>
                    </div>

                    <div className='profile-section-personal-details-main-con'>
                        <div className='profile-section-personal-details-name'>
                            <h3>{profileData?.user_name}</h3>
                            <div className='profile-section-personal-details-name-edit' onClick={showModal}>
                                <MdOutlineModeEdit />
                            </div>
                        </div>

                        <div className='profile-section-persnoal-details-mail-contact-con'>
                            <div className='profile-section-persnoal-details-mail'>
                                <h3>Email</h3>
                                <h4>{profileData?.user_email}</h4>
                            </div>
                            <div className='profile-section-persnoal-details-mail'>
                                <h3>Phone number</h3>
                                <h4>{profileData?.user_phone}</h4>
                            </div>
                        </div>
                    </div>

                    <div className='profile-section-personal-details-main-con address-main-details'>
                        <div className='profile-section-personal-details-name'>
                            <h3>Addresses</h3>
                            <div className='profile-section-personal-details-name-edit' onClick={addressShowModal}>
                                <BsPlus /> <span>Add</span>
                            </div>
                        </div>
                        <div className='profile-section-address-main-con'>
                            <div className='row'>
                                {allAddressData.map((elem) => (
                                    <div className='col-md-3' key={elem.address_id}>
                                        <div className='profile-section-address-main'>
                                            <div className='profile-section-address-con'>
                                                {
                                                    elem.current_active == "1" ? (
                                                        <h3>Default address</h3>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                                <p className='address-type-style'>{elem?.address_type}</p>
                                                <p>{elem?.name}</p>
                                                <p>{elem?.address}</p>
                                                <p>{elem?.landmark}</p>
                                                <p>{elem?.pincode} {elem?.city}</p>
                                                <p>{elem?.state}</p>
                                                <p>{elem?.alternative_number}</p>
                                            </div>
                                            <div className='profile-section-address-icons-con'>
                                                <RxCross2 onClick={() => updateFun(elem)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ProfileModal
                isModalOpen={isModalOpen}
                handleOk={handleCancel}
                handleCancel={handleCancel}
            />
            <AddressModal
                addressHandleOk={addressHandleCancel}
                addressHandleCancel={addressHandleCancel}
                fetchAddresses={fetchAddresses}
                allAddressData={allAddressData}
                addressShowModal={addressShowModal}
                isAddressModalOpen={isAddressModalOpen}
            />
        </>
    )
}

export default Profile;
