import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { IoSend } from "react-icons/io5";
import { FaAngleLeft, FaLock } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function PasswordInput({ otpData,userEmail }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
console.log("otpData",otpData)
  const handleResetPassword = async (value) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("otp", otpData);
      formData.append("newPassword", value.password);
      formData.append("action", "resetPassword")

      const resp = await axios.post("https://1five.in/api/forgetpassword.php", formData);

      if (resp) {
        setLoading(false);
        message.success("Password Reset Successfully");
        navigate("/account/sign-in");
      }else{
        
      }
    } catch (error) {
      console.error(error);
      message.error("Unable to reset password please try again");
    }
  };

  return (
    <div className="inputCard">
      <h4>Reset Password</h4>
      <p>Enter your password you want to reset</p>
      <Form layout="vertical" onFinish={handleResetPassword} autoComplete="off">
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password
            prefix={<FaLock />}
            className="formInput"
            placeholder="Enter Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please enter confirm password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<FaLock />}
            className="formInput"
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} htmlType="submit" className="addButtons bg-dark text-white" icon={<IoSend />}>
            Reset Passwrod
          </Button>
        </Form.Item>
      </Form>
      <Link to="/account/sign-in">
        <FaAngleLeft /> Back to login page
      </Link>
    </div>
  );
}
