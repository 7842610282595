import React, { useEffect, useState } from 'react';
import "../../Css/OrderAddress.css";
import { GoPlus } from "react-icons/go";
import { Link } from 'react-router-dom';
import AddressModal from '../ModalCom/AddressModal';
import AddressSelect from '../modal/AddressSelect';
import { getdeliveryaddress } from '../../services/localStorageServices';
import axios from 'axios';
import { get } from '../../services/ApiRouting';
import { BsPlus } from 'react-icons/bs';

const OrderAddress = ({ selectedAddress,setSelectedAddress}) => {
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [isAddressSelectModalOpen, setIsAddressSelectModalOpen] = useState(false);
    const addressShowModal = () => {
        setIsAddressModalOpen(true);
    };

    const addressHandleCancel = () => {
        setIsAddressModalOpen(false);
    };

    const addressSelectShowModal = () => {
        setIsAddressSelectModalOpen(true);
    };

    const addressSelectHandleCancel = () => {
        setIsAddressSelectModalOpen(false);
    };

    return (
        <>
            <div className='OrderDetials-con OrderAddresscon'>
                <div className='OrderDetials-billing-personal-details-con'>
                    <div className='OrderDetials-billing-personal-details'>
                        <h2>Shipping Details</h2>
                        <div className='OrderAddresscon-personal-deatails'>
                            {/* Display selected address */}
                            {selectedAddress ?
                                <div className='OrderAddresscon-personal-deatails-selected'>
                                    <p>
                                        {selectedAddress?.name}<br/>
                                        {selectedAddress?.address},<br />
                                        {selectedAddress?.landmark},<br />
                                        {selectedAddress?.address_type},<br />
                                        {selectedAddress?.city}, {selectedAddress?.pincode},<br />
                                        {selectedAddress?.state},<br />
                                        Phone Number: {selectedAddress?.alternative_number}
                                    </p>
                                </div>
                                : <h5>No Address added</h5>}
                            {/* Pass the callback function to update selected address */}
                            {
                                selectedAddress ?
                                    <>
                                        <div className='profile-section-checkout-details-name-main-con'>
                                            <div className='profile-section-checkout-details-name-edit add-btn' onClick={addressSelectShowModal}>
                                                <BsPlus /> <span>Select Address</span>
                                            </div>
                                            <div className='profile-section-checkout-details-name-edit' onClick={addressShowModal}>
                                                <BsPlus /> <span>Add</span>
                                            </div>
                                        </div>
                                        <AddressSelect
                                            setSelectedAddress={setSelectedAddress}
                                            selectedAddress={selectedAddress}
                                            addressSelectShowModal={addressSelectShowModal}
                                            addressSelectHandleCancel={addressSelectHandleCancel}
                                            isAddressSelectModalOpen={isAddressSelectModalOpen}
                                            isAddressModalOpen={isAddressModalOpen}
                                        />
                                        <AddressModal
                                            addressShowModal={addressShowModal}
                                            isAddressModalOpen={isAddressModalOpen}
                                            addressHandleOk={addressHandleCancel}
                                            addressHandleCancel={addressHandleCancel}
                                            selectedAddress={selectedAddress}
                                            setSelectedAddress={setSelectedAddress}
                                            // allAddressData={allAddressData}
                                            // setAlladdressData={setAlladdressData}
                                        />
                                    </>
                                    :
                                    <>
                                        <div className='profile-section-checkout-details-name-edit' onClick={addressShowModal}>
                                            <BsPlus /> <span>Add</span>
                                        </div>
                                        <AddressModal
                                            addressShowModal={addressShowModal}
                                            isAddressModalOpen={isAddressModalOpen}
                                            addressHandleOk={addressHandleCancel}
                                            addressHandleCancel={addressHandleCancel}
                                            selectedAddress={selectedAddress}
                                            setSelectedAddress={setSelectedAddress}
                                            // allAddressData={allAddressData}
                                            // setAlladdressData={setAlladdressData}
                                        />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderAddress;
