import "../ProductSlider/HomeProduct.css";
import "../../Css/ProductCard.css";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import AllData from "../ProductData/AllData";
import { get } from "../../services/ApiRouting";


const HomeProduct = () => {
    const [productType, setProductType] = useState("all"); // Default to 'all'
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(false);
    // const fetchData = () => {
    //     let filteredData = [];
    //     switch (productType) {
    //         case "men":
    //             filteredData = AllData.filter((product) => product.product_type === "men");
    //             break;
    //         case "women":
    //             filteredData = AllData.filter((product) => product.product_type === "women");
    //             break;
    //         default:
    //             filteredData = AllData; // Show all products
    //     }
    //     setProductData(filteredData);
    // };

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await get(`/product.php?action=fetchProductsWithDetails&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1${productType !== "all" ? "&gander=" + productType : ""}`);
            setLoading(false)
            setProductData(response.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [productType]);


    return (
        <>
            <section className="Home-product-section-2">
                <div className="Home-product-head-con">
                    <div className="Home-product-head-title-con">
                        <h2>Just Dropped</h2>
                    </div>
                    <div className="Home-Product-select-tabs-con">
                        <div className="Home-Product-select-tabs">
                            <div
                                className={`Home-Product-select-tab ${productType == "all" ? "home-active-tab" : ""}`}
                                onClick={() => setProductType("all")}
                            >
                                <h3>All</h3>
                            </div>
                            <div
                                className={`Home-Product-select-tab ${productType == "women" ? "home-active-tab" : ""}`}
                                onClick={() => setProductType("women")}
                            >
                                <h3>Women</h3>
                            </div>
                            <div
                                className={`Home-Product-select-tab ${productType == "men" ? "home-active-tab" : ""}`}
                                onClick={() => setProductType("men")}
                            >
                                <h3>Men</h3>
                            </div>
                            <div
                                className={`Home-Product-select-tab ${productType == "accessories" ? "home-active-tab" : ""}`}
                                onClick={() => setProductType("accessories")}
                            >
                                <h3>Accessories</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Home-Product-select-card-section-con">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={1}
                        pagination={{
                            clickable: true,
                        }}
                        loop={true}
                        breakpoints={{
                            430: {
                                slidesPerView: 2,
                                spaceBetween: 1,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 1,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 1,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 1,
                            },
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Pagination,Autoplay]}
                        className="mySwiper"
                    >
                        {productData.map((product, productIndex) => (
                            console.log("productData", product),
                            <SwiperSlide key={productIndex}>
                                <div className="product-card-main-con">
                                    <Link to={`/${product?.category?.gander}/${product?.slug}`}>
                                        <div className="product-card-body-con">
                                            <div className="product-card-Image-con product-item__image double__image new-product-item__bg">
                                                <div className="product-item__bg">
                                                    <img
                                                        src={product.colors[0]?.images[0]?.images}
                                                        alt={product.product_name}
                                                    />
                                                </div>
                                                <div className="product-item__bg__under">
                                                    <img
                                                        src={product.colors[0]?.images[1]?.images}
                                                        alt={product.product_name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="Product-card-details-con">
                                                <div className="Product-card-details">
                                                    <div className="product-card-product-name">
                                                        <h4>{product.product_name}</h4>
                                                    </div>
                                                    {/* <div className="Product-card-product-review-price">
                                                        <h6>Rs. {product.colors[0]?.product_price} /-</h6>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>


            <section className="Home-Section-3 bg-image-one">

            </section>

            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}

            <section className="home-page-divder-section-for-images">
                <div className="home-page-divder-section-for-images-text-main-con">
                    <div className="home-page-divder-section-for-images-text">
                        <h3>Dare to Wear Black?</h3>
                        <h4 className="blink_text">All shades of black available.</h4>
                    </div>
                </div>
                <div className="Home-Section-3-btn-con">
                    <Link to="/men">
                        <button>
                            see the collection
                        </button>
                    </Link>
                </div>
            </section>

            <section className="Home-Section-3 bg-image-second">

            </section>

            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}

            <section className="home-page-divder-section-for-images">
                <div className="home-page-divder-section-for-images-text-main-con">
                    <div className="home-page-divder-section-for-images-text">
                        <h3>Black. The Ultimate Luxury.</h3>
                        <h4 className="blink_text">Dressed in Black, Styled for Success.</h4>
                    </div>
                </div>
                <div className="Home-Section-3-btn-con">
                    <Link to="/men">
                        <button>
                            see the collection
                        </button>
                    </Link>
                </div>
            </section>

            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}


            <section className="Home-Section-3 bg-image-third">

            </section>

            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}

            <section className="home-page-divder-section-for-images">
                <div className="home-page-divder-section-for-images-text-main-con">
                    <div className="home-page-divder-section-for-images-text">
                        <h3>Shop Black?</h3>
                        <h4 className="shake">All Black, All Style, All Yours.</h4>
                    </div>
                </div>
                <div className="Home-Section-3-btn-con">
                    <Link to="/men">
                        <button>
                            see the collection
                        </button>
                    </Link>
                </div>
            </section>

            <section className="Home-Section-3 bg-image-fourth">

            </section>

            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}

            <section className="home-page-divder-section-for-images">
                <div className="home-page-divder-section-for-images-text-main-con">
                    <div className="home-page-divder-section-for-images-text">
                        <h3>Shades of Black</h3>
                        <div class="txt" id="txt">
                            <b>1</b><b>F</b><b>I</b><b>V</b><b>E</b>
                        </div>
                    </div>
                </div>
                <div className="Home-Section-3-btn-con animation-btn">
                    <Link to="/men">
                        <button>
                            see the collection
                        </button>
                    </Link>
                </div>
            </section>

            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}


            <section className="Home-Section-3 bg-image-fifth">

            </section>

            <section className="home-page-divder-section-for-images">
                <div className="home-page-divder-section-for-images-text-main-con">
                    <div className="home-page-divder-section-for-images-text">
                        <h4 className="shake">Dark Mode Accessories</h4>
                    </div>
                </div>
                <div className="Home-Section-3-btn-con">
                    <Link to="/Accessories">
                        <button>
                            see the collection
                        </button>
                    </Link>
                </div>
            </section>

            <section className="Home-Section-3 bg-image-sixth">

            </section>


            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}

            <section className="home-page-divder-section-for-images">
                <div className="home-page-divder-section-for-images-text-main-con">
                    <div className="home-page-divder-section-for-images-text">
                        <h4 className="blink_text">Elevate Your Look with Bold Black Accessories</h4>
                    </div>
                </div>
                <div className="Home-Section-3-btn-con">
                <div className="Home-Section-3-btn-con">
                    <Link to="/Accessories">
                        <button>
                            see the collection
                        </button>
                    </Link>
                </div>
                </div>
            </section>

            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}

            <section className="Home-Section-3 bg-image-seven">

            </section>

            {/* dddddddddddddddddddddddddddddddddddddddddddddd */}

            <section className="home-page-divder-section-for-images">
                <div className="home-page-divder-section-for-images-text-main-con">
                    <div className="home-page-divder-section-for-images-text">
                        <h4 className="blink_text">Edgy, Bold, and Always in Black</h4>
                    </div>
                </div>
                <div className="Home-Section-3-btn-con">
                    <Link to="/men">
                        <button>
                            see the collection
                        </button>
                    </Link>
                </div>
            </section>

            <section className="Home-Section-3 bg-image-eight">

            </section>

        </>
    );
};

export default HomeProduct;
