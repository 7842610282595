import React, { useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import "../ModalCom/AddressModal.css";
import { post } from '../../services/ApiRouting';
import { RiHome2Line } from "react-icons/ri";
import { PiBagSimpleBold } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import axios from 'axios';
const AddressModal = ({ isAddressModalOpen, addressHandleOk, addressHandleCancel, setIsAddressModalOpen, AllAddress, setSelectedAddress, fetchAddresses }) => {
    const profileData = JSON.parse(localStorage.getItem('profile'));

    // select btn 
    const [selectedOption, setSelectedOption] = useState('Home'); // Default selected option is "Home"
    const options = [
        { key: 'Home', icon: <RiHome2Line />, label: 'Home' },
        { key: 'Office', icon: <PiBagSimpleBold />, label: 'Office' },
        { key: 'Friend & Family', icon: <IoLocationOutline />, label: 'Friend & Family' },
        { key: 'Other', icon: <IoLocationOutline />, label: 'Other' },
    ];



    const handleFinish = async (values) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', values.name);
            formDataToSend.append('alternative_number', values.alternative_number);
            formDataToSend.append('landmark', values.landmark);
            formDataToSend.append('address_type', selectedOption);
            formDataToSend.append('pincode', values.pincode);
            formDataToSend.append('address', values.address);
            formDataToSend.append('city', values.city);
            formDataToSend.append('state', values.state);
            formDataToSend.append('user_id', profileData.user_id);
            formDataToSend.append('action', "add");
            formDataToSend.append('admin_token', "OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH");
            formDataToSend.append('current_active', "1");
    
            const response = await axios.post('https://1five.in/api/address.php', formDataToSend);
    
            // Check if API response indicates success
            if (response.status >= 200 && response.status <= 300 && response.data?.status === "success") {
                const newAddress = {
                    address_id: response.data.address_id,
                    name: values.name,
                    landmark: values.landmark,
                    address_type: selectedOption,
                    alternative_number: values.alternative_number,
                    pincode: values.pincode,
                    address: values.address,
                    city: values.city,
                    state: values.state,
                    user_id: profileData.user_id,
                    current_active: "1",
                };
    
                message.success('Address added successfully');
                addressHandleCancel(); // Close the modal
                setSelectedAddress(newAddress);
                fetchAddresses();
                AllAddress();
            } else {
                // Handle API failure response
                throw new Error(response.data?.message || "Failed to add address");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // message.error(`Failed to add address ksdckjskdkdskbdskbcskdbn: ${error.message}`);
        }
    };
    


    return (
        <>
            <Modal
                title="Add address"
                visible={isAddressModalOpen}
                onOk={addressHandleOk}
                onCancel={addressHandleCancel}
                width={1000}
                footer={null}
            >
                <div className='profile-modal-main-con'>
                    <div className='address-main-con-form'>
                        <Form layout='vertical' onFinish={handleFinish}>
                            <div className='profile-modal-name-and-surname-con'>
                                <Form.Item name="name" label="Full Name" rules={[{ required: true, message: 'Please enter your full name' }]}>
                                    <Input placeholder='Enter A Full Name' />
                                </Form.Item>
                                <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please enter your address' }]}>
                                    <Input placeholder='Enter Address' />
                                </Form.Item>
                                <Form.Item name="landmark" label="Locality (Optional)">
                                    <Input placeholder='Landmark' />
                                </Form.Item>
                                <Form.Item
                                    name="alternative_number"
                                    label="Alternative Number"
                                    rules={[
                                        { required: true, message: 'Please enter your contact number' },
                                        { pattern: /^[0-9]{10}$/, message: 'Contact number must be 10 digits' }
                                    ]}
                                >
                                    <Input placeholder='Enter Contact No.' maxLength={10} />
                                </Form.Item>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please enter your city' }]}>
                                            <Input placeholder='Enter City' />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4'>
                                        <Form.Item name="state" label="State" rules={[{ required: true, message: 'Please enter your state' }]}>
                                            <Input placeholder='Enter State' />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4'>
                                        <Form.Item
                                            name="pincode"
                                            label="Pincode"
                                            rules={[
                                                { required: true, message: 'Please enter your pincode' },
                                                { pattern: /^[0-9]{6}$/, message: 'Pincode must be 6 digits' }
                                            ]}
                                        >
                                            <Input placeholder='Enter Pincode' maxLength={6} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='address-model-order-types-options-main-con'>
                                    <h4>save as</h4>
                                    <div className='address-model-order-types-option-row'>
                                        {options.map((option) => (
                                            <div
                                                key={option.key}
                                                className={`address-model-order-types-option-col ${selectedOption === option.key ? 'selected' : ''}`}
                                                onClick={() => setSelectedOption(option.key)}
                                                style={{
                                                    border: selectedOption === option.key ? '1px solid black' : '1px solid transparent',
                                                    padding: '10px',
                                                    cursor: 'pointer',
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                {option.icon}
                                                <h5>{option.label}</h5>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                            <Form.Item>
                                <div className='address-model-btn'>
                                    <button type="submit">Submit</button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddressModal;
