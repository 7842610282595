import logo from './logo.svg';
import './App.css';
import Header from './Component/NavBar/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import MenProduct from './Pages/MenProduct';
import WomenProduct from './Pages/WomenProduct';
import AboutUs from './Pages/AboutUs';
import Contact from './Pages/Contact';
import ProductDetails from './Component/ProductInfo/ProductDetails';
import Account from "./Pages/Account"
import CheckOutPage from './Pages/CheckOutPage';
import ProductData from './Component/ProductInfo/ProductData';
import SuccessPage from "./Pages/SuccessPage";
import Order from './Component/OrderCom/Order';
import ScrollToTop from './Component/TopScroll/ScrollToTop';
import ReturnPolicy from './Pages/ReturnPolicy';
import Shipping from './Pages/Shipping';
import PrivacyPolicies from './Pages/PrivacyPolicies';
import FailedPage from './Pages/FailedPage';
import TermsConditions from './Pages/TermsConditions';
import ForgotPassword from "./Component/account/ForgotPassword"
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* <Route path='/men/:slug' element={<><Header someProp={true} /><ProductDetails /></>} /> */}
          {/* <Route path='/women' element={<><Header /><WomenProduct /></>} /> */}
          {/* <Route path='/:slug' element={<><Header someProp={true} /><ProductDetails /></>} /> */}
          <Route path='/' element={<><Header /><Home /></>} />
          <Route path='/:product_type' element={<><Header /><MenProduct /></>} />
          <Route path='/about' element={<><Header /><AboutUs /></>} />
          <Route path='/contact' element={<><Header /><Contact /></>} />
          <Route path='/:product_type/:slug' element={<><Header someProp={true} /><ProductDetails /></>} />
          <Route path="/account/:page" element={<Account />} />
          <Route path='/CheckOutPage' element={<><Header /><CheckOutPage /></>} />
          <Route path='/order-success/:order_id' element={<SuccessPage />} />
          {/* <Route path='/order-success' element={<SuccessPage />} /> */}
          <Route path='/order-failed/:order_id' element={<FailedPage />} />
          {/* <Route path='/order-failed' element={<FailedPage />} /> */}
          <Route path='/order' element={<Order />} />
          <Route path='/account/forgot-password' element={<ForgotPassword />} />
          <Route path='/return-policy' element={<><Header someProp={true} /><ReturnPolicy /></>} />
          <Route path='/shipping-policy' element={<><Header someProp={true} /><Shipping /></>} />
          <Route path='/privacy-policies' element={<><Header someProp={true} /> <PrivacyPolicies /></>} />
          <Route path='/terms-conditions' element={<><Header someProp={true} /> <TermsConditions /></>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
