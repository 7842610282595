import React, { useEffect, useState } from 'react';
import "../Css/SuccessPage.css";
import { PiSealCheckFill } from "react-icons/pi";
import { useCartContext } from '../context/addToCart';
import { Link, useParams } from 'react-router-dom';
import deliverymanriding from "../Images/delivery-man-riding.png";
import { get } from '../services/ApiRouting';
import moment from 'moment';

const SuccessPage = () => {
    const { cartValue, setCartCount, setCartValue } = useCartContext();
    const profileData = JSON.parse(localStorage.getItem('profile'));
    const { order_id } = useParams();

    const [orderProduct, setOrderProduct] = useState(null);
    const [mainProductData, setMainProductData] = useState(null)
    const [address, setAddress] = useState(null)
    console.log("orderProduct", orderProduct)
    const fetchData = async () => {
        try {
            // const response = await get(`orders.php?action=fetch&order_id=${order_id}&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1&user_id=${profileData.user_id}`);
            const response = await get(`orders.php?action=fetch&order_id=${order_id}&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1`);
            setMainProductData(response.orders[0])
            setAddress(response.orders[0].address)
            setOrderProduct(response.orders[0].products);
            console.log("responce", response)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [order_id]);

    const roundedBasePrice = Math.round(mainProductData?.subtotal / 1.18); 
    const totaltax = Math.round(mainProductData?.subtotal - roundedBasePrice);

    return (
        <>
            <section className='SuccessPage-Section-con'>
                <div className='container'>
                    <div className='SuccessPage-Section'>
                        <div className='SuccessPage-Section-Details-con'>
                            <div className='order-Success-con'>
                                <div className='order-Success-con-icons'>
                                    <PiSealCheckFill />
                                    <h2>Order Confirmed</h2>
                                    <p>Thank you for shopping with 1Five! We're excited to let you know that your order has been successfully confirmed. Our team is hard at work preparing your items with the utmost care. We appreciate your trust in us and can't wait for you to enjoy your purchase. Thanks for choosing 1Five, where quality and satisfaction are our top priorities.</p>
                                </div>
                                <div className='Order-Success-Details-con-Main'>
                                    <div className='Order-Success-Details-con-Main-head'>
                                        <div className='Order-Success-List-head'>
                                            <h3>Order Id : - <span>{mainProductData?.order_id}</span></h3>
                                        </div>
                                        <div className='Order-Success-List-head'>
                                            <h3>Ordered on : - <span>{moment(mainProductData?.created_at).format("MMMM D, YYYY h:mm:ss A")}</span></h3>
                                        </div>
                                    </div>
                                    <div className='Order-Success-Details-con'>
                                        <div className='Order-Success-Details-add'>
                                            <h1>delivering to :</h1>
                                            <div className='Order-Success-Details-name-contact'>
                                                <h2>
                                                    {address && address?.city}
                                                </h2>
                                                <span>
                                                    |
                                                </span>
                                                <h2>
                                                    {address && address?.alternative_number}
                                                </h2>
                                            </div>
                                            <div className='Order-Success-Details-address'>
                                                <p>{address?.address}, {address?.city}, {address?.state}, {address?.pincode}</p>
                                            </div>
                                        </div>
                                        <div className='Order-Success-Details-Image-con'>
                                            <img src={deliverymanriding} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className='Order-Success-List-con'>
                                    <div className="Order-Success-List">
                                        <div className='Product-addedCart-Side-Bar Successpage-Product-addedCart-Side-Bar' id="style-4">
                                            {
                                                orderProduct && orderProduct.map((elem, index) => {
                                                    const { productName, productImage, productColor, productSize, product_qty, product_price, colors, sizes, images, details } = elem;
                                                    return (
                                                        <div key={index} className='Product-addedCart-Side-details'>
                                                            <div className='Product-addedCart-Image-con'>
                                                                <div className='Product-addedCart-Image'>
                                                                    <img src={images[0]?.images} alt={productName} />
                                                                </div>
                                                            </div>
                                                            <div className='Product-details-addedCart-price-name SuccessPageprice'>
                                                                <div className='Product-details-addedCart-price'>
                                                                    <h2>{details?.product_name} <br></br>({details?.product_type})</h2>
                                                                    <h3><span>Color : </span>{colors[0]?.color_name}</h3>
                                                                    <h3><span>Size : </span>{sizes[0]?.product_size}</h3>
                                                                    <h3><span>Quantity : </span>{product_qty}</h3>
                                                                </div>
                                                                <h4>{product_price}/- Rs</h4>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div className='Product-details-Addedcart-total-con'>
                                            <div className='Product-details-Addedcart-total order-successpage-product-detail-final'>
                                                <div className='Product-details-Addedcart-total-title'>
                                                    <h4>Subtotal:</h4>
                                                    <h4>Tax (18%):</h4>
                                                    <h4>BasePrice:</h4>
                                                    <h4>Total:</h4>
                                                </div>
                                                <div className='Product-details-Addedcart-total-Amount'>
                                                    <h5>{mainProductData?.subtotal} /- Rs</h5>
                                                    <h5>{totaltax} /- Rs</h5>
                                                    <h5>{roundedBasePrice} /- Rs</h5>
                                                    <h5>{mainProductData?.amount} /- Rs</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-success-page-btn-con'>
                                            <Link to="/">
                                                <button>
                                                    Back to home
                                                </button>
                                            </Link>
                                            <Link to="/men">
                                                <button>
                                                    Continue Shopping
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SuccessPage;
