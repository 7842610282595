import React, { useState, useEffect } from 'react';
import "./ProductDetails.css";
import { Link, useLocation, useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import ProductData from '../ProductData/MenData'; // Adjust the path to where your ProductData is located
import Footer from "../FooterComponent/Footer";
import { useCartContext } from '../../context/addToCart';
import axios from 'axios';
import { get } from '../../services/ApiRouting';
import { Flex, Image, Spin } from 'antd';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
const ProductDetails = () => {

    const { slug } = useParams()
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState(null);
    const [productPrice, setProductPrice] = useState(0);
    const [productIndex, setProductIndex] = useState(0);
    const [productQuantity, setProductQuantity] = useState(1);
    const [mainImages, setMainImage] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [isOutOfStock, setIsOutOfStock] = useState(false);

    const {
        handleAddCart,
        selectedSize,
        setSelectedSize,
        selectedColor,
        setSelectedColor,
        setSelectedPrice,
        selectedImages,
        setSelectedImages,
        selectedPrice,
        setSelectedProduct,
    } = useCartContext();

    useEffect(() => {
        if (product) {
            setProduct(product);
            console.log('product.colors[0]', product)
            // Default settings for the selected product
            const defaultColor = product.colors[0];
            const defaultSize = defaultColor.sizes[0];
            setSelectedProduct(product);
            setSelectedImages(defaultColor.image);
            setSelectedSize(defaultSize);
            setSelectedColor(defaultColor);
            setSelectedPrice(product.colors[0].sizes[0].price);
            setProductPrice(product.colors[0].sizes[0].price);
            setIsOutOfStock(defaultSize.out_of_stock);
        } else {
            setProduct(null); // Clear the product if not found
        }
    }, [slug]); // Depend on Path to re-run when it changes


    const singleProductFetch = async () => {
        try {
            const response = await get(`/product.php?action=fetchProductsWithDetails&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1&slug=${slug}`);
            setLoading(true)
            setProduct(response.list[0]);
            const foundProduct = response.list[0];
            if (foundProduct) {
                setLoading(false)
                const defaultColor = foundProduct?.colors?.[0];
                const defaultSize = defaultColor.sizes[0];
                setSelectedProduct(foundProduct);
                setSelectedImages(defaultColor.images);
                setSelectedSize(defaultSize);
                setSelectedColor(defaultColor);
                setSelectedPrice(defaultSize.price);
                setProductPrice(defaultSize.price);
                setIsOutOfStock(defaultSize.out_of_stock);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        singleProductFetch()
    }, [slug]);

    const handleColorSelection = (colorItem) => {
        if (colorItem && colorItem.image && colorItem.size) {
            const defaultSize = colorItem?.sizes[0];
            setSelectedSize(defaultSize);
            setSelectedColor(colorItem);
            setSelectedImages(colorItem?.images);
            setSelectedPrice(colorItem?.product_price);
            setMainImage(colorItem?.images[0]?.images);
            setProductIndex(0);
        }
    };


    const handleSizeSelection = (size) => {
        console.log("size", size)
        setSelectedPrice(size.price);  // Update price when size is selected
        setSelectedSize(size);
        setProductPrice(size.price);  // Ensure product price also updates
        setIsOutOfStock(size.out_of_stock == 0);  // Correcting the condition
    };

    const handleImageChange = (index) => {
        setActiveIndex(index); // Update the active image index
        setMainImage(selectedImages[index]?.images); // Change the main image
    };


    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <>
            <section className='Product-details-section1-main-con'>
                <Spin spinning={loading}>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className='Product-details-Section-1-Images-Main-Con'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='Product-details-section-1-Side-Images-con'>
                                            {selectedImages && selectedImages.length > 1 &&
                                                selectedImages.map((product_image, index) => (
                                                    <div
                                                        key={index}
                                                        className={`Product-details-section-1-Side-Image-div ${index === activeIndex ? 'active' : ''}`}
                                                        onClick={() => handleImageChange(index)} // Update Swiper on click
                                                    >
                                                        {product_image && (
                                                            <img src={product_image.images} alt={`Color ${index + 1}`} />
                                                        )}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className='col-md-9'>
                                        <div className='Product-details-section-1-Side-Main-Image-con'>
                                            <Swiper
                                                spaceBetween={30}
                                                centeredSlides={true}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: false,
                                                }}
                                                loop={true}
                                                modules={[Autoplay, Pagination, Navigation]}
                                                className="mySwiper"
                                                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)} // Update the active index
                                                initialSlide={activeIndex} // Set the initial slide based on activeIndex
                                            >
                                                {selectedImages && selectedImages.length > 0 &&
                                                    selectedImages.map((product_image, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div className='swaiper-product-details-images-main-cond'>
                                                                <Image src={product_image.images} alt={`Product ${index + 1}`} />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                            </Swiper>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='Product-Details-Section-1-Details-main-con'>
                                <div className='Product-Details-Section-1-Details-navigation-con'>
                                    <div className='Product-Details-Section-1-Details-navigation'>
                                        <Link to={`/category/${product.product_type}`}>
                                            <h2 className='Product-details-navigation'>{product.product_type}</h2>
                                        </Link>
                                        <span> / </span>
                                        <Link to={`/product/${product.slugs}`}>
                                            <h2 className='Product-details-navigation'>{product.product_name}</h2>
                                        </Link>
                                    </div>
                                    <div className='Product-Details-Section-1-Details-name-price-main-con'>
                                        <div className='Product-Details-Section-1-Details-name'>
                                            <h2>{product.product_name}</h2>
                                        </div>
                                        <div className='Product-Details-Section-1-Details-Price'>
                                            <h4>Rs. {selectedPrice} /-</h4>
                                        </div>
                                    </div>
                                    <div className='Product-Details-Section-1-Details-color-main-con'>
                                        {product && product.colors.map((colorItem, index) => (

                                            <div
                                                className='Product-Details-Section-1-Details-color'
                                                key={index}
                                                style={{
                                                    backgroundColor: colorItem.color_code,
                                                    border: selectedColor && selectedColor.color_code === colorItem.color_code ? '5px solid black' : 'none'
                                                }}
                                                onClick={() => handleColorSelection(colorItem)}
                                            />
                                        ))}
                                    </div>
                                    {
                                        selectedSize?.product_size == 0 ? (
                                            <>
                                                <div className='Product-Details-Section-1-Details-size-main-con' style={{ margin: "7px" }}>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='Product-Details-Section-1-Details-size-main-con'>
                                                    {selectedColor && selectedColor?.sizes?.map((sizeItem, index) => (
                                                        <div
                                                            className='Product-Details-Section-1-Details-size-con'
                                                            key={index}
                                                            style={{
                                                                border: selectedSize.product_size == sizeItem.product_size ? '1px solid black' : 'none'
                                                            }}
                                                            onClick={() => handleSizeSelection(sizeItem)}
                                                        >
                                                            <h4>{sizeItem.product_size}</h4>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className='Product-Details-Section-1-Btn-con'>
                                        <button
                                            onClick={() => handleAddCart({
                                                Quantity: productQuantity,
                                                productprice: productPrice,
                                            })}
                                            disabled={isOutOfStock} // Disable if out_of_stock is 1
                                            style={{ backgroundColor: isOutOfStock ? "gray" : "" }}
                                        >
                                            {isOutOfStock ? "Out of Stock" : "Add to Cart"}
                                        </button>
                                    </div>
                                    <div className='Product-Details-Section-1-Details-accordion-main-con'>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Description</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='Accordion-Body-content-con'>
                                                        {/* <p>{product.product_desc}</p> */}
                                                        <p>
                                                            <span>Brand : </span> 1Five
                                                        </p>
                                                        <p>
                                                            <span>Article : </span> {product.product_name}
                                                        </p>
                                                        <p>
                                                            <span>Colour : </span> BLACK
                                                        </p>
                                                        <p>
                                                            <span>Code : </span> {product.product_code}
                                                        </p>
                                                        <p>
                                                            <span>Price : </span> Rs. {selectedColor && selectedColor.product_price}
                                                        </p>
                                                        {
                                                            product?.category?.gander == "Accessories" ? (
                                                                null
                                                            ) : (
                                                                <>
                                                                    <p>
                                                                        <span>Size : </span> Ind-xl | uk-xl | usa-L
                                                                    </p>
                                                                </>
                                                            )
                                                        }
                                                        <p>
                                                            <span>Material : </span> {product?.material}
                                                        </p>
                                                        {
                                                            product?.care == null ? (
                                                                null
                                                            ) : (
                                                                <>
                                                                    <p>
                                                                        <span>Care : </span> {product?.care}
                                                                    </p>
                                                                </>
                                                            )
                                                        }
                                                        <p>
                                                            <span>Country Origin : </span> India
                                                        </p>
                                                        {/* <p>
                                                            <span>Manufactured By : </span> 1Five India
                                                        </p> */}

                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </section>
            <Footer />
        </>
    );
}

export default ProductDetails;
