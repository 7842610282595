import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Upload, Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import "../ModalCom/profileModal.css";
import { post } from '../../services/ApiRouting';
import { setProfile } from '../../services/localStorageServices';

const ProfileModal = ({ isModalOpen, handleOk, handleCancel }) => {
    const [form] = Form.useForm();
    const [profileData, setProfileData] = useState(() => {
        return JSON.parse(localStorage.getItem('profile')) || {};
    });
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (profileData) {
            form.setFieldsValue({
                name: profileData.user_name,
                email: profileData.user_email,
                contact: profileData.user_phone,
                user_id: profileData.user_id
            });

            // Set default profile image if available
            if (profileData.user_image) {
                setFileList([
                    {
                        uid: '-1',
                        name: 'profile.png',
                        status: 'done',
                        url: profileData.user_image, // Set existing profile image
                    },
                ]);
            } else {
                setFileList([]); // Reset if no image
            }
        }
    }, [profileData, form]);

    const onFinish = async (values) => {
        console.log("values", values);
        const formDataToSend = new FormData();
        formDataToSend.append('user_name', values.name);
        formDataToSend.append('user_email', values.email);
        formDataToSend.append('user_phone', values.contact);
        formDataToSend.append('user_id', profileData.user_id);
        formDataToSend.append('action', 'update');
        formDataToSend.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');

        if (fileList.length > 0 && fileList[0].originFileObj) {
            formDataToSend.append('image', fileList[0].originFileObj);
        }

        try {
            const response = await post(`/user.php`, formDataToSend);
            if (response.status === "Update successful") {
                setProfile(response?.user);
                message.success('Profile updated successfully');
                handleOk();
            }
        } catch (error) {
            console.error('Error Updating Data:', error);
            message.error('Failed to update profile');
        }
    };

    return (
        <Modal title="Edit Profile" visible={isModalOpen} onOk={form.submit} onCancel={handleCancel} width={1000}>
            <div className='profile-modal-main-con'>
                <Form layout='vertical' form={form} onFinish={onFinish}>
                    <div className='profile-modal-name-and-surname-con'>
                        <Form.Item name="name" label="First Name" rules={[{ required: true, message: 'Please enter your first name.' }]}>
                            <Input placeholder='First Name' />
                        </Form.Item>
                        <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address.' }]}>
                            <Input placeholder='Enter Email' />
                        </Form.Item>
                        <Form.Item
                            name="contact"
                            label="Phone Number"
                            rules={[
                                { required: true, message: 'Please enter your phone number.' },
                                { pattern: /^\d{10}$/, message: 'Phone number must be exactly 10 digits.' }
                            ]}
                        >
                            <Input placeholder='Enter Contact Number' maxLength={10} />
                        </Form.Item>
                        <Form.Item label="Profile Image">
                            <Upload
                                listType="picture-card"
                                fileList={fileList}
                                beforeUpload={() => false} // Prevent automatic upload
                                onChange={({ fileList }) => setFileList(fileList.slice(-1))} // Keep only the latest file
                                onRemove={() => setFileList([])} // Allow removal of existing image
                            >
                                {fileList.length === 0 && (
                                    <button
                                        style={{ color: 'inherit', cursor: 'pointer', border: 0, background: 'none' }}
                                        type="button"
                                    >
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </button>
                                )}
                            </Upload>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default ProfileModal;
