import React, { useEffect, useState } from "react";
import "../../Css/YourOrder.css";
import { useCartContext } from "../../context/addToCart";
import axios from "axios";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { message } from 'antd';
import { get } from "../../services/ApiRouting";
import Successbtn from "../successandfailed/Successbtn";
import emptyCart from "../../Images/add_to_cart.jpg";

const PayOrder = ({ selectedAddress, setSelectedAddress, allAddressData, setAlladdressData }) => {
  const { cartValue, setCartCount, setCartValue } = useCartContext();
  const [productId, setProductId] = useState([]);

  const profileData = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    if (cartValue.length > 0) {
      const productData = cartValue.map(item => ({
        product_id: item.productid,
        color_id: item.colorid,
        size_id: item.sizeid,
        product_qty: item.productQuantity,
        product_price: item.product_price
      }));
      setProductId(productData);
    }
  }, [cartValue]);

  const removeCartData = (index) => {
    setCartValue((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      addtoLocatStorage(updatedData);
      setCartCount(updatedData.length);
      return updatedData;
    });
  };

  const addtoLocatStorage = (data) => {
    localStorage.setItem("cart", JSON.stringify(data));
  };

  let dateTime = new Date();
  dateTime = dateTime.getTime();

  const tokenid = localStorage.getItem("access_token")
  useEffect(() => {
    // fetchDataAdd();
    generateTransactionId()
  }, [tokenid]);


  const generateTransactionId = () => {
    // Generate transaction ID based on current date and time
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Create a transaction ID with a length of exactly 17 characters
    let transactionId = year.toString() +
      month +
      day +
      hours +
      minutes +
      seconds;

    // Ensure transaction ID length is exactly 17 characters
    const remainingLength = 17 - transactionId.length;
    if (remainingLength > 0) {
      transactionId += Math.floor(Math.random() * (10 ** remainingLength)).toString();
    } else if (remainingLength < 0) {
      transactionId = transactionId.substring(0, 17); // Trim if exceeds 17 characters
    }

    return transactionId;
  };

  const error = () => {
    if (!tokenid) {
      message.error('Please log in first to place the order.');
    } else if (selectedAddress == null) {
      message.error('Please select a delivery address.');
    }
  };

  const [deliveryCharges, setDeliveryCharges] = useState(0);



  let finalsubtotal = 0;
  cartValue.forEach(product => {
    Math.round(finalsubtotal += parseFloat(product.fixedPrice * product.productQuantity));
  });

  // Extracting 18% GST from the included price (Correct)
  const roundedBasePrice = Math.round(finalsubtotal / 1.18); // More concise and accurate rounding

  // Base price without GST (Correct)
  // const basePrice = finalsubtotal / (1 + gstRate);

  // Rounding basePrice to two decimal places (Correct and important)
  const totaltax = Math.round(finalsubtotal - roundedBasePrice);

  // Calculate totalamount
  let totalamount = finalsubtotal + deliveryCharges;
  const generateOrderId = () => {
    const randomNumber = Math.floor(1000000 + Math.random() * 9000000); // Generates a 7-digit number
    return `ORDER${randomNumber}`;
  };

  return (
    <>

      {
        cartValue.length <= 0 ?
          (
            <>
              <div className="YourOrder-Con-Main">
                <div class="txt" id="txt">
                  <b>1</b><b>F</b><b>I</b><b>V</b><b>E</b>
                </div>
                <div className="checkout-page-cart-empty-imaeg-main-con">
                  <div className="checkout-page-cart-empty-imaeg-con">
                    <img src={emptyCart} alt="emptyCart" />
                  </div>
                </div>
                <div className="plz-add-product-into-cart">
                  <h3>
                    Add Product Into Cart
                  </h3>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="YourOrder-Con-Main">
                <div className="YourOrder-Con-head-Main">
                  <h2>Your Order</h2>
                </div>
                <div className="YourOrder-Con-Title-Main">
                  <h2>Product</h2>
                  <h2>Total</h2>
                </div>
                <div className="Product-addedCart-Side-Bar">
                  {cartValue.map((elem, index) => (
                    console.log("cartksdnksn",elem),
                    <div className="Product-addedCart-Side-details" key={index}>
                      <div className="Product-addedCart-Image-con">
                        <div className="Product-addedCart-Image">
                          <img src={elem.productImage} alt={elem.productName} />
                        </div>
                      </div>
                      <div className="Product-details-addedCart-price-name">
                        <h2>{elem.productName}</h2>
                        {
                      elem?.productSize == 0 ? (
                        null
                      ) : (
                        <h3><span>Size : {elem.productSize}</span></h3>
                      )
                    }
                        {/* <h3><span>Size : {elem.productSize}</span></h3> */}
                        <h3><span>Quantity : {elem.productQuantity}</span></h3>
                        <h3><span>Color : {elem.productColor}</span></h3>
                        <h3><span>Price : </span>{elem.fixedPrice} ₹</h3>
                      </div>
                      <div className="Product-details-addedCart-cancel-btn">
                        <div
                          className="Product-details-addedCart-cancel-btn-con"
                          onClick={() => removeCartData(index)}
                        >
                          <RxCross2 />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="Product-addedCart-Side-details-1">
                    <h3>Hassle-free shopping – Price includes 18% GST!</h3>
                    <div className='YourOrder-Con-details-Main'>
                      <div className='YourOrder-Con-details-text'>
                        <h2 className='YourOrder-totoal'>Subtotal</h2>
                      </div>
                      <h2>₹ {finalsubtotal}</h2>
                    </div>
                    <div className='YourOrder-Con-details-Main'>
                      <div className='YourOrder-Con-details-text'>
                        <h2 className='YourOrder-totoal'>Delivery Charges</h2>
                      </div>
                      <h2>Free</h2>
                    </div>
                    <div className='YourOrder-Con-details-Main'>
                      <div className='YourOrder-Con-details-text'>
                        <h2 className='YourOrder-totoal'>Base Price</h2>
                      </div>
                      <h2>₹ {roundedBasePrice}</h2>
                    </div>
                    <div className='YourOrder-Con-details-Main'>
                      <div className='YourOrder-Con-details-text'>
                        <h2 className='YourOrder-totoal'>Tax</h2>
                      </div>
                      <h2>₹ {totaltax}</h2>
                    </div>
                    <div className='YourOrder-Con-details-Main'>
                      <div className='YourOrder-Con-details-text'>
                        <h2 className='YourOrder-totoal'>Total Amount</h2>
                      </div>
                      <h2>₹ {Math.round(totalamount)}</h2>
                      {/* <h2>₹ {finalsubtotal}</h2> */}
                    </div>
                  </div>
                  <div className="Product-details-Addedcart-btn">
                    <div className="Product-details-Addedcart-btn-ViewCart">
                      {tokenid && selectedAddress ? ( // If tokenid exists
                        <form
                          method="post"
                          name="customerData"
                          action="https://1five.in/api/PhonePe/payment-request.php"
                        >
                          <input
                            type="hidden"
                            name="user_id"
                            value={profileData && profileData.user_id}
                          />
                          <input
                            type="hidden"
                            name="address_id"
                            value={selectedAddress && selectedAddress?.address_id}
                          />
                          <input
                            type="hidden"
                            name="status"
                            value="pending"
                          />
                          <input
                            type="hidden"
                            name="amount"
                            value={totalamount.toFixed(2)}
                          />
                          <input
                            type="hidden"
                            name="subtotal"
                            value={finalsubtotal.toFixed(2)}
                          />
                          <input
                            type="hidden"
                            name="tax_amount"
                            value={totaltax.toFixed(2)}
                          />
                          <input
                            type="hidden"
                            name="delivery_charges"
                            value="0"
                          />
                          <input
                            type="hidden"
                            name="order_id"
                            value={generateOrderId()}
                          />
                          {cartValue.map((v, i) => (
                            <>
                              <input type="hidden" name={`products[${i}][product_id]`} className="form-control" value={v?.productid} />
                              <input type="hidden" name={`products[${i}][size_id]`} className="form-control" value={v?.sizeid} />
                              <input type="hidden" name={`products[${i}][color_id]`} className="form-control" value={v?.colorid} />
                              <input type="hidden" name={`products[${i}][product_qty]`} className="form-control" value={v?.productQuantity} />
                              <input type="hidden" name={`products[${i}][product_price]`} className="form-control" value={v?.productQuantityPrice} />
                            </>
                          ))}
                          <button type="submit">Place Your Order and Pay</button>
                          {/* <Successbtn/> */}
                        </form>
                      ) : (
                        <button onClick={error}>Place Your Order and Pay</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
      }

    </>
  );
};

export default PayOrder;
