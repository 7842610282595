import React from 'react';
import "../SettingCom/Settings.css";
import { GoLock } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
const Settings = () => {
    const navigate = useNavigate();
    const logoutHandle = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('profile');
        navigate('/');
    };

    return (
        <>
            <section className='setting-section'>
                <div className='container'>
                    <div className='setting-section-head-con'>
                        <h2>Settings</h2>
                    </div>
                    <div className='setting-section-details-main-con'>
                        <div className='setting-section-details-main'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className='setting-section-details-icon-name-con'>
                                        <div className='setting-section-details-icon-name'>
                                            <GoLock />
                                            <h3>Log out everywhere</h3>
                                        </div>
                                        <p>If you've lost a device or have security concerns, log out everywhere to ensure the security of your account.</p>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <div className='Setting-section-logout-btn-main-con'>
                                        <div className='Setting-logout-and-info-con'>
                                            <button onClick={logoutHandle}>
                                                Log out everywhere
                                            </button>
                                            <p>You will be logged out on this device as well.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Settings