import React, { useEffect, useMemo, useState } from 'react';
import "../OrderCom/OrderList.css";
import { CiCalendar } from "react-icons/ci";
import { Drawer, Form, Input, message, Modal, Rate } from 'antd';
import moment from 'moment';
import { useCartContext } from '../../context/addToCart';
import "../../Css/MyOrder.css";
import { get, post } from '../../services/ApiRouting';
import { Button, Popover } from 'antd';
import { Pagination } from 'antd';
import Footer from '../FooterComponent/Footer';
const OrderList = () => {
    const [open, setOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const [reviewModal, setReviewModal] = useState(false)
    const [isReviewExisting, setIsReviewExisting] = useState(false)
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm()
    const [rating, setRating] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [productID, setProductID] = useState(null)
    const [yourOrderProduct, setYourOrderProduct] = useState(null);
    const { cartValue } = useCartContext();
    const tokenid = localStorage.getItem("access_token")
    const profile = JSON.parse(localStorage.getItem('profile'))
    const itemsPerPage = 2; // Number of items per page
    const handleCancel = () => {
        setReviewModal(false);
    };
    const showDrawer = (order) => {
        setOrderDetails(order);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const Content = ({ address_1, address_2, state, city, pincode }) => (
        <div className='Order-Details-Address'>
            <p>{address_1}</p>
            <p>{address_2}</p>
            <p>{city}, {state} {pincode}</p>
        </div>
    );

    // const text = <span>Title</span>;
    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);

    const fetchData = async (page) => {
        try {
            const response = await get(`/orders.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1&user_id=${profile.user_id}`);
            setYourOrderProduct(response.orders);
            setTotalItems(response.orders.length); // Assuming response contains total number of items
            console.log("Fetched data:", response.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Fetch data when component mounts and when currentPage changes
    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const onFinish = async (values) => {
        if (!profile?.user_id) {
            message.error('Please Login First.');
            return;
        }

        const formData = new FormData();
        setLoading(true);

        try {
            formData.append('review', values.reviewContent);
            formData.append('stars', values.rating);
            formData.append('user_id', profile.user_id);
            formData.append('product_id', productID);
            formData.append('admin_token', 'OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH');

            if (isReviewExisting) {
                // Send request to update the existing review
                formData.append('action', 'update');
                formData.append('review_id', values.reviewId);
                const response = await post('/review.php', formData);
                if (response) {
                    message.success('Review updated successfully');
                }
            } else {
                // Send request to add a new review
                formData.append('action', 'add');
                const response = await post('/review.php', formData);
                if (response) {
                    message.success('Review submitted successfully');
                }
            }
        } catch (error) {
            console.error('Error submitting review:', error);
        } finally {
            setLoading(false);
            setReviewModal(false);
        }
    };

    return (
        <>
            <section className='order-section-order-list-main-con'>
                <div className='container'>
                    <div className='order-section-order-list-head'>
                        <h2>Orders</h2>
                    </div>
                    <div className='order-section-order-list-con'>
                        {/* <div className='order-section-empty-list-text'>
                            <h2>No orders yet</h2>
                            <h3>Go to store to place an order.</h3>
                        </div> */}

                        <div className='MyOrder-Section-con'>
                            {yourOrderProduct === null ? (
                                <p>Looks like you haven't placed an order</p>
                            ) : (
                                <>
                                    {yourOrderProduct && yourOrderProduct?.map((elem, index) => {
                                        const { address, created_at, amount, order_id, status, products } = elem
                                        console.log("products", products)
                                        return (
                                            <>
                                                <div className='Product-addedCart-Side-Bar orderlistmaincon'>
                                                    <div className='Orderlist-Details-Head-Con'>
                                                        <div className='row'>
                                                            <div className='col-md-4 col-sm-4 col-12'>
                                                                <div className='OrderList-Details-Date'>
                                                                    <h2>Order Placed</h2>
                                                                    <h3>{moment(created_at).format("llll")}</h3>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3 col-sm-4 col-6'>
                                                                <div className='OrderList-Details-Date'>
                                                                    <h2>Ship To</h2>
                                                                    <h3>
                                                                        <Popover placement="bottom" content={<Content address_1={address.address} city={address.city} state={address.state} pincode={address.pincode} />} arrow={mergedArrow}>
                                                                            <Button>{profile.username}</Button>
                                                                        </Popover>
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2 col-sm-4 col-6'>
                                                                <div className='OrderList-Details-Date'>
                                                                    <h2>Total</h2>
                                                                    <h3>{amount}</h3>
                                                                </div>
                                                            </div>

                                                            <div className='col-md-3'>
                                                                <div className='OrderList-Details-Date orderidcon'>
                                                                    <h2>ORDER {order_id}</h2>
                                                                    <div className='OrderList-Status-Details'>
                                                                        <div className='OrderList-Status-Details-btn'>
                                                                            {status}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        products && products?.map((elem) => {
                                                            const { details, product_qty, product_price } = elem
                                                            return (
                                                                <>
                                                                    <div className='Product-addedCart-Side-details orderList-con'>
                                                                        <div className='Product-details-addedCart-price-name order-details-addedCart-price-name'>
                                                                            <h3><span>Name: </span>{details?.product_name}</h3>
                                                                            <h3><span>Quantity: </span>{product_qty}</h3>
                                                                            <h3><span>Price: </span>{product_price}/- Rs</h3>
                                                                        </div>
                                                                        {/* {status === 'success' && (
                                                                    <div className='Product-details-addedCart-cancel-btn-con OrderProduct-Btn-on'>
                                                                        <button onClick={() => handleReviewModal(product_id)}>
                                                                            <p>
                                                                            <div>
                                                                                <Rate />
                                                                            </div>
                                    
                                                                            </p>
                                                                            {isReviewExisting ? 'Update Review' : 'Review'}
                                                                        </button>
                                                                    </div>
                                                                )} */}

                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    <div className='Product-details-addedCart-cancel-btn-con'>
                                                        <div className='Product-details-addedCart-cancel-btn-con OrderProduct-Btn-Con'>
                                                            <button onClick={() => showDrawer(elem)}>
                                                                View order details
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    }
                                </>
                            )}
                            <Pagination
                                current={currentPage} // Current active page
                                total={totalItems}    // Total number of items from the API
                                pageSize={itemsPerPage} // Items per page
                                onChange={(page) => setCurrentPage(page)} // Change page
                            />
                        </div>

                        <Drawer title="Order Details" onClose={onClose} open={open}>
                            {orderDetails && (
                                <section className='Order-Details-Drawer'>
                                    <div className='Order-Details-Drawer-Details-order-Id'>
                                        <div className='Order-Details-First-Part'>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <div className='Order-DetailsName'>
                                                        <h2>Ordet Date</h2>
                                                    </div>
                                                </div>
                                                <div className='col-7'>
                                                    <div className='Order-Details-dec'>
                                                        <h3>{moment(orderDetails?.created_at).format("llll")}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='Order-Details-First-Part'>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <div className='Order-DetailsName'>
                                                        <h2>order</h2>
                                                    </div>
                                                </div>
                                                <div className='col-7'>
                                                    <div className='Order-Details-dec'>
                                                        <h3>{orderDetails?.order_id}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='Order-Details-First-Part'>
                                            <div className='row'>
                                                <div className='col-5'>
                                                    <div className='Order-DetailsName'>
                                                        <h2>Ordet total</h2>
                                                    </div>
                                                </div>
                                                <div className='col-7'>
                                                    <div className='Order-Details-dec'>
                                                        <h3>Rs. {orderDetails?.amount}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Order-Details-Drawer-Details-order-Id'>
                                        <div className='Order-Details-Drawer-HeadDetails'>
                                            <h2>Shipment details</h2>
                                        </div>
                                        {
                                            orderDetails && orderDetails?.products.map((elem) => {
                                                console.log("orderDetails", orderDetails)
                                                const { details, images, product_size, product_price, product_qty } = elem
                                                return (
                                                    <>
                                                        <div className='Order-details-Drawer-Shipment-details'>
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <div className='Order-details-Drawer-Product-Image'>
                                                                        <img src={images[0]?.images} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-8'>
                                                                    <div className='Order-details-Drawer-Product-Details'>
                                                                        <h3>{details?.product_name}</h3>
                                                                        <h4>Qty: <span>{product_qty}</span></h4>
                                                                        <h4>Price: <span>{product_price}</span></h4>
                                                                        <h4>Size: <span>{product_size}</span></h4>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='Order-Details-Drawer-Details-order-Id'>
                                        <div className='Order-Details-Drawer-HeadDetails'>
                                            <h2>Payment Methods</h2>
                                            <p>Online</p>
                                        </div>
                                        <div className='Order-details-Drawer-Shipment-details'>
                                            <div className='Order-details-Drawer-Product-Details'>
                                                <h3>{orderDetails?.username}</h3>
                                                <p>{orderDetails?.address?.address}</p>
                                                <p>{orderDetails?.address?.alternative_number}</p>
                                                <p>{orderDetails?.address?.city}, {orderDetails?.address?.state}, {orderDetails?.address?.pincode}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='Order-Details-Drawer-Details-order-Id'>
                                        <div className='Order-Details-Drawer-HeadDetails'>
                                            <h2>Order Summary</h2>
                                        </div>
                                        <div className='Order-details-Drawer-Shipment-details'>
                                            <div className='Order-details-Drawer-Product-Details'>
                                                <div className='row'>
                                                    <div className='col-7'>
                                                        <div className='Order-details-Drawer-payment-details'>
                                                            <h3>Subtotal:</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className='Order-details-Drawer-payment-number'>
                                                            <h4>{orderDetails?.subtotal}/- Rs</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='Order-details-Drawer-Product-Details'>
                                                <div className='row'>
                                                    <div className='col-7'>
                                                        <div className='Order-details-Drawer-payment-details'>
                                                            <h3>Tax:</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className='Order-details-Drawer-payment-number'>
                                                            <h4>{orderDetails.tax_amount}/- Rs</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='Order-details-Drawer-Product-Details'>
                                                <div className='row'>
                                                    <div className='col-7'>
                                                        <div className='Order-details-Drawer-payment-details'>
                                                            <h3 className='Order-details-Drawer-OrderTotal'>Total Amount:</h3>
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className='Order-details-Drawer-payment-number'>
                                                            <h4 className='Order-details-Drawer-Orderamount'>{orderDetails?.amount}/- Rs</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Drawer>
                        <Modal
                            open={reviewModal}
                            title={isReviewExisting ? 'Update Your Review' : 'Write a Review'}
                            onOk={() => form.submit()}
                            confirmLoading={loading}
                            onCancel={handleCancel}
                        >
                            <div>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="rating"
                                        label="Rating"
                                        rules={[{ required: true, message: 'Please provide a rating' }]}
                                    >
                                        <Rate onChange={(value) => setRating(value)} value={rating} />
                                    </Form.Item>
                                    <Form.Item
                                        name="reviewContent"
                                        label="Review"
                                        rules={[{ required: true, message: 'Please share your experience' }]}
                                    >
                                        <Input.TextArea rows={6} placeholder="How was your overall experience?" />
                                    </Form.Item>

                                    <Form.Item name="reviewId" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>

                                </Form>
                            </div>
                        </Modal>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default OrderList