import React, { useLayoutEffect } from 'react';
import "../Css/PrivacyPolicies.css";
import Footer from '../Component/FooterComponent/Footer';


const PrivacyPolicies = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <section className='privacy-policies-section-con'>
                <div className='container'>
                    <div className='privacy-policies-section'>
                        <div className='privacy-policies-section-head'>
                            <h2>Privacy Policy</h2>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>1 FIVE</h3>
                            <p> Black Addiction is committed to protecting your privacy. This privacy policy ("Policy") explains how we ("1 FIVE", "we", or "us") gather and handle your personal information to ensure a satisfying shopping experience. We take your privacy seriously and treat your personal information with respect and care.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Scope of Policy</h3>
                            <p>This Policy applies to the collection of Personal Information (as detailed below) during interactions with you, whether in-store, by phone, via customer service, or through our website/app, 1FIVE.in ("Site"). By using our Site, services, or providing us with Personal Information, you agree to the practices described in this Policy and our Terms and Conditions. If you do not agree with this Policy, please do not use our Site or share your Personal Information.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Who We Are</h3>
                            <p>"1 FIVE" refers to 1 FIVE FASHION INDIA and its subsidiaries and affiliates, including 1 FIVE INDIA, 1 FIVE PARIS, 1 FIVE ELECTRONICS, and 1 FIVE ACCESSORIES.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>What Is Personal Information?</h3>
                            <p>Personal Information is any data that identifies you personally, such as your name, billing address, email, postal address, phone numbers, and credit or debit card information.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>How We Collect Personal Information</h3>
                            <p>We gather Personal Information in our stores and online when you register or update your profile, subscribe to communications, use customer services, participate in surveys, contests, or promotions, and interact on social media. If you send an email or buy a gift card for someone else, we will also collect their Personal Information. Our Site is for adults, and we do not knowingly collect information from children under 13.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Purpose of Collection</h3>
                            <p>We use your Personal Information to enhance your shopping experience, communicate about products, promotions, and contests, protect against fraud, process orders, track purchases, assess creditworthiness, and comply with legal requirements. We also use this information to offer you relevant products, develop and improve our Site, and conduct research and surveys.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Consent</h3>
                            <p>We collect, use, and disclose your Personal Information with your knowledge and consent, as outlined in this Policy. Consent may be given explicitly or implied through your actions, such as when you engage with our services. You can change or withdraw your consent at any time, subject to legal or contractual restrictions. If you provide Personal Information about another person, you must have their consent for us to use it as described.                            </p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Limiting Use, Disclosure, and Retention</h3>
                            <p>We only use and disclose your Personal Information for the purposes for which it was collected, unless you consent otherwise or as required by law. We retain your information only as long as necessary for those purposes or as required by law.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Accuracy and Safeguards</h3>
                            <p>We strive to keep your Personal Information accurate and up-to-date. You may request access to and correction of your information at any time. We protect your Personal Information with appropriate physical and electronic security measures. We are responsible for information under our control, including when transferred to third parties for processing.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Openness and Individual Access</h3>
                            <p>This Policy is available on our Site at all times. You can request information about our use and disclosure of your Personal Information and challenge its accuracy and completeness.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Contact Us</h3>
                            <p>For questions, comments, or to withdraw consent, contact us at <a>support@1FIVE.in</a> We will respond to inquiries and complaints appropriately.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Assignment</h3>
                            <p>Your Personal Information may be transferred to a third-party acquirer if 1 FIVE's business is sold.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Cookies and Web Beacons</h3>
                            <p>We use cookies and web beacons to improve Site functionality and personalize your experience. You can disable cookies in your browser settings. Web beacons help us track visits and email interactions.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <h3>Links to Other Sites</h3>
                            <p>Our Site may contain links to external sites with their own privacy policies. We are not responsible for these sites' content or practices. We recommend reviewing their policies before providing any personal information.</p>
                        </div>
                        <div className='privacy-policies-section-details-con'>
                            <p>
                                The refund will be credited in the bank account within 7-8 business days.
                            </p>
                            <p>
                                The product once shipped will be delivered within 7-8 working days.
                            </p>
                            <p>
                                The return will be picked up within 2-3 working days.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default PrivacyPolicies