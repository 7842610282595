import React, { useEffect, useState, useRef } from 'react';
import { MdSearch } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';
import { get } from '../../services/ApiRouting';
import "../SearchComponent/SearchPage.css";

const SearchPage = ({ toggleSearch, isSearchOpen }) => {
    const [visible, setVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    
    const debounceTimer = useRef(null);
    const navigate = useNavigate();

    const handleNavigate = (data) => {
        navigate(`/${data?.category?.gander}/${data?.slug}`);
        setVisible(false);
        setSearchTerm(""); // Fixed: Set to empty string instead of null
    };

    useEffect(() => {
        if (isSearchOpen) {
            setVisible(true);
        } else {
            setTimeout(() => setVisible(false), 500);
        }
    }, [isSearchOpen]);

    useEffect(() => {
        if (searchTerm.length > 2) {
            setIsTyping(true);

            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }

            debounceTimer.current = setTimeout(() => {
                fetchProducts();
                setIsTyping(false);
            }, 1000);
        }
    }, [searchTerm]);

    const fetchProducts = async () => {
        try {
            const searchQuery = searchTerm ? `&search=${searchTerm}` : "";
            const response = await get(
                `/product.php?action=fetchProductsWithDetails&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=4&page=1${searchQuery}`
            );
            setProducts(response.list || []);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    return (
        <>
            {visible && (
                <section className={`search-section ${isSearchOpen ? 'show' : 'hide'}`}>
                    <div className='container'>
                        <div className='search-display-info'>
                            <h3>{products.length} result{products.length !== 1 ? 's' : ''} for {searchTerm}</h3>
                        </div>
                        <div className='search-section-main-con'>
                            <div className='search-section-con'>
                                <div className='search-icons-con'>
                                    <MdSearch />
                                </div>
                                <div className='search-bar-main-con'>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        style={{ color: "white" }}
                                    />
                                </div>
                                <div className='search-cancel-con' onClick={toggleSearch}>
                                    <RxCross2 />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='search-section-2'>
                        <div className='search-section2-main-con'>
                            <div className='row product-list-row'>
                                {searchTerm.length > 2 ? (
                                    products.length > 0 ? (
                                        products.map((product, productIndex) =>
                                            product.colors.map((colorItem, colorIndex) => (
                                                <div key={`${productIndex}-${colorIndex}`} className='col-lg-3 col-md-4 col-sm-6 col-12 product-list-col'>
                                                    <div className="product-card-main-con" onClick={() => handleNavigate(product)}>
                                                        <div className="product-card-body-con">
                                                            <div className="product-card-Image-con product-item__image double__image">
                                                                <div className="product-card-Image-con product-item__image double__image new-product-item__bg">
                                                                    <div className="product-item__bg">
                                                                        <img src={product?.colors[0]?.images?.[0]?.images} alt="Product 1" />
                                                                    </div>
                                                                    <div className="product-item__bg__under">
                                                                        <img src={product?.colors[0]?.images?.[1]?.images} alt="Product 2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="Product-card-details-con">
                                                                <div className="Product-card-details">
                                                                    <div className="product-card-product-name">
                                                                        <h4>{product.product_name}</h4>
                                                                    </div>
                                                                    <div className="Product-card-product-review-price">
                                                                        <h6>Rs. {colorItem.product_price?.toString() || ''} /-</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    ) : (
                                        <div className='product-card-search-not-found-text'>
                                            <h3>No results found</h3>
                                        </div>
                                    )
                                ) : null}
                            </div>

                            {products.length >= 4 && (
                                <div className='search-popup-btn-main-con'>
                                    <button>View More</button>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default SearchPage;
