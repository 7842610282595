import React, { useEffect, useLayoutEffect, useState } from 'react';
import "../Css/MenProduct.css";
import { HiMenuAlt1 } from "react-icons/hi";
import { Link, useParams } from 'react-router-dom';
import Footer from "../Component/FooterComponent/Footer";
import axios from 'axios';
import { Button, Checkbox, Drawer, Collapse, message } from 'antd';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { get } from '../services/ApiRouting';
import { useCartContext } from '../context/addToCart';
import { FaStar } from 'react-icons/fa';

const { Panel } = Collapse;


const MenProduct = () => {
    const { product_type } = useParams()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const [loading, setLoading] = useState(false);
    const [menproduct, setMenProduct] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showSortDropdown, setShowSortDropdown] = useState(false); // State to toggle sort dropdown
    const [categoryData, setCategoryData] = useState(null)

    const [categorySelection, setCategorySelection] = useState('All'); // Default to "All" selected

    const [availabilitySelection, setAvailabilitySelection] = useState('All'); // Default to "All" selected
    const [availabilityValue, setAvailabilityValue] = useState('All'); // Holds the numeric value
    const {
        setSelectedSize,
        setSelectedColor,
        setSelectedPrice,
        setSelectedImages,
        selectedPrice,
        setSelectedProduct,
    } = useCartContext();

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await get(`/product.php?action=fetchProductsWithDetails&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1${product_type !== "" ? "&gander=" + product_type : "All"}${categorySelection !== "" ? "&category_name=" + categorySelection : "All"}${availabilityValue !== "All" ? "&out_stock=" + availabilityValue : ""}`);
            setLoading(false)
            setMenProduct(response.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchCategory = async () => {
        try {
            const response = await get(`/category.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&gander=${product_type}`);
            setCategoryData(response.list)
        } catch (error) {
            console.log('Error fetching data:', error)
        }
    }

    useEffect(() => {
        fetchData();
        fetchCategory();
    }, [product_type, availabilitySelection, availabilityValue, categorySelection]);

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const toggleSortDropdown = () => {
        setShowSortDropdown(!showSortDropdown);
    };

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const handleQuickAdd = (product) => {
        setSelectedProduct(product);
        // Example action: add to cart
        // handleAddCart(product); // Assuming handleAddCart is a function to add product to cart
        // Example notification
        const defaultColor = product.colors[0];
        const defaultSize = defaultColor.size[0];
        setSelectedImages(defaultColor.image);
        console.log("first", defaultColor.image)
        setSelectedSize(defaultSize);
        setSelectedColor(defaultColor);
        setSelectedPrice(defaultSize.price);
    };

    // check details 
    const handleAvailabilityChange = (checkedValue) => {
        setAvailabilitySelection(checkedValue); // Only one selection at a time
        setAvailabilityValue(checkedValue === 'In stock' ? 0 : checkedValue === 'Out of stock' ? 1 : 'All');
    };

    const handleCategoryChange = (checkedValue) => {
        setCategorySelection(checkedValue); // Only one selection at a time
    };



    return (
        <>
            <section className='men-product-banner-section'>
                <div className='men-product-banner-con'>
                    {/* Add your banner content here */}
                </div>
            </section>

            <section className='men-section-filter-con'>
                <div className='men-section-filert'>
                    <div className='men-section-main-filert'>
                        <div className='men-section-main-filert-details' onClick={showDrawer}>
                            <h2>Filter</h2>
                            <HiMenuAlt1 />
                        </div>
                    </div>
                    {/* <div className='men-section-main-sort-by'>
                        <div className='men-section-main-filert-details2' onClick={toggleSortDropdown}>
                            <h2>Sort By</h2>
                            <MdOutlineKeyboardArrowDown />
                        </div>
                        <div className={`men-section-main-sort-by-dropdown ${showSortDropdown ? 'open' : ''}`}>
                            <button>Best seller</button>
                            <button>Price, low to high</button>
                            <button>Price, high to low</button>
                            <button>New arrivals</button>
                        </div>
                    </div> */}
                </div>
            </section>

            <section className='men-section-3-product-list-con'>
                <div className='row product-list-row'>
                    {menproduct && menproduct.map((product, productIndex) => (
                        product && product.colors.map((colorItem, colorIndex) => (
                            console.log("colorItem",colorItem?.sizes?.[0].price                            ),
                            <div key={`${productIndex}-${colorIndex}`} className='col-lg-3 col-md-4 col-sm-6 col-12 product-list-col'>
                                <div className="product-card-main-con">
                                    <Link to={`${product.slug}`}>
                                        <div className="product-card-body-con">
                                            <div className="product-card-Image-con product-item__image double__image">
                                                <div className="product-card-Image-con product-item__image double__image new-product-item__bg">
                                                    <div className="product-item__bg">
                                                        <img src={product?.colors[0]?.images?.[0]?.images} />
                                                    </div>
                                                    <div className="product-item__bg__under">
                                                        <img src={product?.colors[0]?.images?.[1]?.images} />
                                                    </div>
                                                </div>
                                                <div className="slideup is-not-relative">
                                                    <button
                                                        type="button"
                                                        className="caps"
                                                        data-focus-element=""
                                                        data-quick-add-label="1 Five-x-memeland-finger-tee"
                                                        data-collection-handle="primary"
                                                        tabIndex={0}
                                                        onClick={() => handleQuickAdd(product)}
                                                    >
                                                        <span>Quick add</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="Product-card-details-con">
                                                <div className="Product-card-details">
                                                    <div className="product-card-product-name">
                                                        <h4>{product.product_name}</h4>
                                                    </div>
                                                    <div className="Product-card-product-review-price">
                                                        <h6>Rs. {colorItem.sizes?.[0] ? colorItem.sizes?.[0].price : ''} /-</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))
                    ))}
                    {/* {
                        MenData && MenData.map((product, productIndex) => (
                            product.colors.map((colorItem, colorIndex) => (
                            <div key={productIndex} className='col-lg-3 col-md-4 col-sm-6 product-list-col'>
                                <div className="product-card-main-con">
                                    <Link to={`${product.slug}`}>
                                        <div className="product-card-body-con">
                                            <div className="product-card-Image-con product-item__image double__image new-product-item__bg">
                                                <div className="product-item__bg">
                                                    <img src={product?.colors[0]?.images?.[0]?.images} />
                                                </div>
                                                <div className="product-item__bg__under">
                                                    <img src={product?.colors[0]?.images?.[1]?.images} />
                                                </div>
                                            </div>
                                            <div className="Product-card-details-con">
                                                <div className="Product-card-details">
                                                    <div className="product-card-product-name">
                                                        <h4>{product?.product_name}</h4>
                                                    </div>
                                                    <div className="Product-card-product-review">
                                                        <div className="Product-card-product-review-star">
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                            <FaStar />
                                                        </div>
                                                        <div className="Product-card-product-review-text">
                                                            <h5>1 Review</h5>
                                                        </div>
                                                    </div>
                                                    <div className="Product-card-product-review-price">
                                                        <h6>Rs. {product?.colors[0]?.product_price} /-</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            ))
                        ))
                    } */}
                </div>

            </section>

            <div className='Drawer-main-con'>
                <Drawer title="153 ITEMS" placement="left" onClose={onCloseDrawer} visible={openDrawer}>
                    <Collapse defaultActiveKey={['1', '2']} expandIconPosition="right">
                        {/* Availability Panel */}
                        <Panel header="Availability" key="1">
                            <div className="Drawer-Availability-details-con">
                                {['All', 'In stock', 'Out of stock'].map((option) => (
                                    <div className="Drawer-Availability-check-box" key={option}>
                                        <Checkbox
                                            checked={availabilitySelection === option}
                                            onChange={() => handleAvailabilityChange(option)}
                                        >
                                            {option}
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        </Panel>


                        {/* Category Panel */}
                        <Panel header="Category" key="2">
                            <div className='Drawer-Availability-details-con'>
                                <div className='Drawer-Availability-check-box'>
                                    <Checkbox
                                        checked={categorySelection.includes('All')}
                                        onChange={() => handleCategoryChange('All')}
                                    >
                                        All
                                    </Checkbox>
                                </div>
                                {categoryData &&
                                    categoryData.map((elem) => (
                                        <div className='Drawer-Availability-check-box' key={elem.category_name}>
                                            <Checkbox
                                                checked={categorySelection.includes(elem.category_name)}
                                                onChange={() => handleCategoryChange(elem.category_name)}
                                            >
                                                {elem.category_name}
                                            </Checkbox>
                                        </div>
                                    ))}
                            </div>
                        </Panel>
                    </Collapse>
                </Drawer>
            </div>

            <Footer />
        </>
    );
};

export default MenProduct;
