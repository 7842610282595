import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { get } from "../../services/ApiRouting";

const AddressSelect = ({ setSelectedAddress, selectedAddress,isAddressModalOpen,addressSelectHandleCancel,isAddressSelectModalOpen }) => {
  const profileData = JSON.parse(localStorage.getItem('profile'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState([])

  const showModal = async () => {
    setIsModalOpen(true);
    addressListfetch()
  };

  const addressListfetch = async ()=>{
    try {
      const response = await get(`/address.php?action=fetch&limit=10&page=1&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&user_id=${profileData.user_id}`);
      if (response && response.list) {
        setAddressList(response.list);
      } else {
        message.error('Failed to fetch addresses.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch addresses.');
    }
  }

  useEffect (()=>{
    showModal()
    addressListfetch()
  },[isAddressModalOpen]);

  const handleOk = async () => {
    if (selectedAddress) {
      // await updateAddress(selectedAddress);
    }
    addressSelectHandleCancel();
  };

  const handleCancel = () => {
    addressSelectHandleCancel();
  };

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  return (
    <>
      <Modal
        title="Select Address"
        visible={isAddressSelectModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="OrderDetials-billing-personal-detailsOrderAddresscon">
          {addressList && addressList.map((elem, index) => (
            // console.log('current_active',elem),
            <div key={elem.address_id || index} className="OrderAddresscon-personal-details">
              <input
                type="radio"
                name="address"
                id={"id" + elem.address_id}  // Use address_id if it's unique
                className="form-input-check"
                onClick={() => handleAddressChange(elem)}
                checked={elem.address_id == selectedAddress.address_id}  // Compare current_active to check the radio button
              />
              <label htmlFor={"id" + elem.address_id}>  {/* Use elem.address_id for htmlFor */}
                {elem?.name},<br />
                {elem?.address},<br />
                {elem?.landmark},<br />
                {elem?.address_type},<br />
                {elem?.city}, {elem?.pincode},<br />
                {elem?.state},<br />
                Phone Number: {elem?.alternative_number}
              </label>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default AddressSelect;
