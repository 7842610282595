import React, { useEffect, useState } from 'react';
import "../Css/CheckOutPage.css";
import Footer from '../Component/FooterComponent/Footer';
import OrderDeatils from '../Component/checkout/OrderDeatils';
import OrderAddress from '../Component/checkout/OrderAddress';
import Orderlogin from '../Component/checkout/Orderlogin';
import { useSelector } from "react-redux";
import PayOrder from '../Component/checkout/PayOrder';
import { get } from '../services/ApiRouting';
import AddressModal from '../Component/ModalCom/AddressModal';

const CheckOutPage = () => {
    const tokenid = localStorage.getItem("access_token");
    const profileData = JSON.parse(localStorage.getItem('profile'));
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [allAddressData, setAlladdressData] = useState(null);
    const AllAddress = async () => {
        try {
            const response = await get(`/address.php?action=fetch&limit=10&page=1&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&user_id=${profileData.user_id}`);
            const addressListData = response.list;
            console.log("addressListData", addressListData)
            setAlladdressData(addressListData);
            // Find the address with current_active == 1
            const activeAddress = addressListData.find(address => address.current_active == 1);
            console.log("activeAddress", activeAddress)
            setSelectedAddress(activeAddress)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        AllAddress();
    }, [tokenid]);

    const { token } = useSelector((state) => state.auth);

    return (
        <>
            <section className='men-product-banner-section'>
                <div className='men-product-banner-con'>
                </div>
            </section>

            <section className='CheckOutPage-Section-2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            {token ? (
                                <>
                                    <OrderDeatils />
                                    <OrderAddress
                                        selectedAddress={selectedAddress}
                                        setSelectedAddress={setSelectedAddress}
                                        allAddressData={allAddressData}
                                        setAlladdressData={setAlladdressData}
                                    />
                                    <AddressModal
                                        selectedAddress={selectedAddress}
                                        setSelectedAddress={setSelectedAddress}
                                        allAddressData={allAddressData}
                                        setAlladdressData={setAlladdressData}
                                    />
                                </>
                            ) : (
                                <Orderlogin />
                            )}
                        </div>
                        <div className='col-md-5'>
                            <PayOrder
                                selectedAddress={selectedAddress}
                                setSelectedAddress={setSelectedAddress}
                                allAddressData={allAddressData}
                                setAlladdressData={setAlladdressData}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CheckOutPage;
