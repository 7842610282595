import React, { useState } from 'react';
import "../OrderCom/Order.css";
import Logo from "../../Images/Logo/New Footer Logo.png";
import { Link, useNavigate } from 'react-router-dom';
import { RiArrowDownSLine } from "react-icons/ri";
import { Avatar, Dropdown, Space } from 'antd';
import OrderList from './OrderList';
import Profile from '../ProfileCom/Profile';
import Settings from '../SettingCom/Settings';
import { AiOutlineUser } from "react-icons/ai";
const Order = () => {
    const [selectedComponent, setSelectedComponent] = useState('OrderList');
    const profileData = JSON.parse(localStorage.getItem('profile'));

    const navigate = useNavigate();
    const logoutHandle = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('profile');
        navigate('/');
    };
    const items = [
        {
            key: '1',
            label: (
                <div className='order-profile-head-info-main-con'>
                    <div className='order-profile-head-info-image-and-name-main-con'>
                        <div className='Profile-dropdown-profile-name-con'>
                            <Avatar
                                size="large"
                                src={profileData?.image ? profileData.image : undefined}
                                icon={!profileData?.image && <AiOutlineUser />}
                            />
                        </div>
                        <div className='order-profile-name-and-email-con'>
                            <h4>{profileData.username}</h4>
                            <h5>{profileData.user_email}</h5>
                        </div>
                    </div>
                </div>
            ),
            disabled: true,
        },
        {
            key: '2',
            label: (
                <div className='Profile-drop-down-link' onClick={() => setSelectedComponent('Profile')}>
                    <Link to="#">
                        Profile
                    </Link>
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div className='Profile-drop-down-link' onClick={() => setSelectedComponent('Settings')}>
                    <Link to="#">
                        Settings
                    </Link>
                </div>
            ),
        },
        {
            key: '4',
            label: (
                <div className='Profile-drop-down-link' onClick={() => setSelectedComponent('OrderList')}>
                    <Link to="/order">
                        Order
                    </Link>
                </div>
            ),
        },
        {
            key: '5',
            label: (
                <div className='Profile-drop-down-link' onClick={logoutHandle}>
                    Log Out
                </div>
            ),
        },
    ];

    return (
        <>
            <section className='order-main-section'>

                <section className='Order-section-1'>
                    <div className='order-section-head-con-main'>
                        <div className='container'>
                            <div className='order-section-head-bar'>
                                <div className='order-section-logo-and-order-btn-con'>
                                    <div className='order-section-logo-image-con'>
                                        <img src={Logo} alt='Logo' />
                                    </div>
                                    <div className='Order-section-bar-order-text'>
                                        <h3>Orders</h3>
                                    </div>
                                </div>
                                <div className='order-section-bar-profile-dropdown-and-store-main-con'>
                                    <div className='order-section-bar-profile-dropdown-btn'>
                                        <Dropdown
                                            menu={{
                                                items,
                                            }}
                                        >
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space>

                                                    {/* <div className='Profile-dropdown-profile-name-con'>
                                                        <div className='Profile-dropdown-profile-name'>
                                                            <h3>ST</h3>
                                                        </div>
                                                    </div> */}
                                                    <div className='Profile-dropdown-profile-name-con'>
                                                        <Avatar
                                                            size="large"
                                                            src={profileData?.image ? profileData.image : undefined}
                                                            icon={!profileData?.image && <AiOutlineUser />}
                                                        />
                                                    </div>
                                                    <RiArrowDownSLine />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </div>
                                    <div className='order-section-bar-store-btn-con'>
                                        <Link to="/">
                                            <button>
                                                Go to store
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {selectedComponent === 'OrderList' && <OrderList />}
                {selectedComponent === 'Profile' && <Profile />}
                {selectedComponent === 'Settings' && <Settings />}
{/* 
                <section className='order-section-order-footer-links-main-con'>
                    <div className='container'>
                        <div className='order-section-order-footer-links-con'>
                            <div className='order-section-order-footer-links'>
                                <Link to="#">
                                    <h4>Refund Policy</h4>
                                </Link>
                            </div>
                            <div className='order-section-order-footer-links'>
                                <Link to="#">
                                    <h4>Shipping Policy</h4>
                                </Link>
                            </div>
                            <div className='order-section-order-footer-links'>
                                <Link to="#">
                                    <h4>Privacy Policy</h4>
                                </Link>
                            </div>
                            <div className='order-section-order-footer-links'>
                                <Link to="#">
                                    <h4>Terms of service</h4>
                                </Link>
                            </div>
                            <div className='order-section-order-footer-links'>
                                <Link to="#">
                                    <h4>Contact us</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section> */}
            </section>
        </>
    );
}

export default Order;
